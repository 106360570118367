@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-LightItalic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Book.otf') format('truetype');
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-BookItalic.otf') format('truetype');
  font-weight: 350;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Italic.otf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-MediumItalic.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Semibold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-SemiboldItalic.otf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-BoldItalic.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Black.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-BlackItalic.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

/* body {
  font-family: 'Utile', 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
} */

/*---- Font Sizes From Design. All units in rem
 Set to !important so we can override. the name suffix is the unit in pixel*/

.font-utile {
  font-family: 'Utile', 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
}

.fs-10 {
  font-size: 0.625rem !important;
}
.fs-12 {
  font-size: 0.75rem !important;
}
.fs-14 {
  font-size: 0.875rem !important;
}
.fs-16 {
  font-size: 1rem !important;
}
.fs-18 {
  font-size: 1.125rem !important;
}
.fs-18-29 {
  font-size: 1.143rem !important;
}
.fs-19-30 {
  font-size: 1.206rem !important;
}
.fs-20 {
  font-size: 1.25rem !important;
}
.fs-22 {
  font-size: calc(1.2625rem + 0.15vw) !important;
}
@media (min-width: 1200px) {
  .fs-22 {
    font-size: 1.375rem !important;
  }
}
.fs-24 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
@media (min-width: 1200px) {
  .fs-24 {
    font-size: 1.5rem !important;
  }
}
.fs-28 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
@media (min-width: 1200px) {
  .fs-28 {
    font-size: 1.75rem !important;
  }
}
.fs-30 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}
@media (min-width: 1200px) {
  .fs-30 {
    font-size: 1.875rem !important;
  }
}
.fs-32 {
  font-size: calc(1.325rem + 0.9vw) !important;
}
@media (min-width: 1200px) {
  .fs-32 {
    font-size: 2rem !important;
  }
}
.fs-34 {
  font-size: calc(1.3375rem + 1.05vw) !important;
}
@media (min-width: 1200px) {
  .fs-34 {
    font-size: 2.125rem !important;
  }
}
.fs-36 {
  font-size: calc(1.35rem + 1.2vw) !important;
}
@media (min-width: 1200px) {
  .fs-36 {
    font-size: 2.25rem !important;
  }
}
.fs-38 {
  font-size: calc(1.3625rem + 1.35vw) !important;
}
@media (min-width: 1200px) {
  .fs-38 {
    font-size: 2.375rem !important;
  }
}
.fs-40 {
  font-size: calc(1.375rem + 1.5vw) !important;
}
@media (min-width: 1200px) {
  .fs-40 {
    font-size: 2.5rem !important;
  }
}
.fs-42 {
  font-size: calc(1.3875rem + 1.65vw) !important;
}
@media (min-width: 1200px) {
  .fs-42 {
    font-size: 2.625rem !important;
  }
}
.fs-44 {
  font-size: calc(1.4rem + 1.8vw) !important;
}
@media (min-width: 1200px) {
  .fs-44 {
    font-size: 2.75rem !important;
  }
}
.fs-46 {
  font-size: calc(1.4125rem + 1.95vw) !important;
}
@media (min-width: 1200px) {
  .fs-46 {
    font-size: 2.875rem !important;
  }
}
.fs-46-33 {
  font-size: calc(1.4125rem + 1.95vw) !important;
}
@media (min-width: 1200px) {
  .fs-46-33 {
    font-size: 2.875rem !important;
  }
}
.fs-47-28 {
  font-size: calc(1.4205rem + 2.046vw) !important;
}
@media (min-width: 1200px) {
  .fs-47-28 {
    font-size: 2.955rem !important;
  }
}
.fs-48 {
  font-size: calc(1.425rem + 2.1vw) !important;
}
@media (min-width: 1200px) {
  .fs-48 {
    font-size: 3rem !important;
  }
}
.fs-56 {
  font-size: calc(1.475rem + 2.7vw) !important;
}
@media (min-width: 1200px) {
  .fs-56 {
    font-size: 3.5rem !important;
  }
}
.fs-60 {
  font-size: calc(1.5rem + 3vw) !important;
}
@media (min-width: 1200px) {
  .fs-60 {
    font-size: 3.75rem !important;
  }
}
.fs-64 {
  font-size: calc(1.525rem + 3.3vw) !important;
}
@media (min-width: 1200px) {
  .fs-64 {
    font-size: 4rem !important;
  }
}
.fs-80 {
  font-size: calc(1.625rem + 4.5vw) !important;
}
@media (min-width: 1200px) {
  .fs-80 {
    font-size: 5rem !important;
  }
}
.lh-p120 {
  line-height: 1.2;
}
.lh-p140 {
  line-height: 1.4;
}
.lh-100 {
  line-height: 1;
}
.lh-49 {
  line-height: calc(1.435rem + 2.22vw);
}
@media (min-width: 1200px) {
  .lh-49 {
    line-height: 3.1rem;
  }
}
.lh-32 {
  line-height: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .lh-32 {
    line-height: 2rem;
  }
}
.lh-33 {
  line-height: calc(1.33125rem + 0.975vw);
}
@media (min-width: 1200px) {
  .lh-33 {
    line-height: 2.0625rem;
  }
}
.lh-34 {
  line-height: calc(1.34118125rem + 1.094175vw);
}
@media (min-width: 1200px) {
  .lh-34 {
    line-height: 2.1618125rem;
  }
}
.lh-27 {
  line-height: calc(1.29375rem + 0.525vw);
}
@media (min-width: 1200px) {
  .lh-27 {
    line-height: 1.6875rem;
  }
}
.lh-49 {
  line-height: calc(1.435rem + 2.22vw);
}
@media (min-width: 1200px) {
  .lh-49 {
    line-height: 3.1rem;
  }
}
.lh-70 {
  line-height: calc(1.56625rem + 3.794999999999999vw);
}
@media (min-width: 1200px) {
  .lh-70 {
    line-height: 4.4125rem;
  }
}
/*Margins */
/* Top */
.mt-250 {
  margin-top: calc(2.6875rem + 17.25vw);
}
@media (min-width: 1200px) {
  .mt-250 {
    margin-top: 15.625rem;
  }
}
.mt-200 {
  margin-top: calc(2.375rem + 13.5vw);
}
@media (min-width: 1200px) {
  .mt-200 {
    margin-top: 12.5rem;
  }
}
.mt-159 {
  margin-top: calc(2.11875rem + 10.424999999999999vw);
}
@media (min-width: 1200px) {
  .mt-159 {
    margin-top: 9.9375rem;
  }
}
.mt-100 {
  margin-top: calc(1.75rem + 6vw);
}
@media (min-width: 1200px) {
  .mt-100 {
    margin-top: 6.25rem;
  }
}
.mt-80 {
  margin-top: calc(1.625rem + 4.5vw);
}
@media (min-width: 1200px) {
  .mt-80 {
    margin-top: 5rem;
  }
}
/*Margins Bottom */
.mb-250 {
  margin-bottom: calc(2.6875rem + 17.25vw);
}
@media (min-width: 1200px) {
  .mb-250 {
    margin-bottom: 15.625rem;
  }
}
.mb-200 {
  margin-bottom: calc(2.375rem + 13.5vw);
}
@media (min-width: 1200px) {
  .mb-200 {
    margin-bottom: 12.5rem;
  }
}
.mb-100 {
  margin-bottom: calc(1.75rem + 6vw);
}
@media (min-width: 1200px) {
  .mb-100 {
    margin-bottom: 6.25rem;
  }
}
/*Negative margins */
.mt--1 {
  margin-top: -0.25rem;
}
.mt--2 {
  margin-top: -0.5rem;
}
.mt--3 {
  margin-top: -1rem;
}
.mt--4 {
  margin-top: calc(-1.275rem - 0.3vw);
}
@media (min-width: 1200px) {
  .mt--4 {
    margin-top: -1.5rem;
  }
}
.mt--5 {
  margin-top: calc(-1.425rem - 2.1vw);
}
@media (min-width: 1200px) {
  .mt--5 {
    margin-top: -3rem;
  }
}
.mt--6 {
  margin-top: calc(-1.475rem - 2.7vw);
}
@media (min-width: 1200px) {
  .mt--6 {
    margin-top: -3.5rem;
  }
}
.mr--3,
.me--3 {
  margin-right: -1rem;
}
.mr--4,
.me--4 {
  margin-right: calc(-1.275rem - 0.3vw);
}
@media (min-width: 1200px) {
  .mr--4,
  .me--4 {
    margin-right: -1.5rem;
  }
}
.mr--5,
.me--5 {
  margin-right: calc(-1.425rem - 2.1vw);
}
@media (min-width: 1200px) {
  .mr--5,
  .me--5 {
    margin-right: -3rem;
  }
}
.mr--2,
.me--2 {
  margin-right: -0.5rem;
}
/*Paddings */
/* Top */
.pt-300 {
  padding-top: calc(3rem + 21vw);
}
@media (min-width: 1200px) {
  .pt-300 {
    padding-top: 18.75rem;
  }
}
.pt-200 {
  padding-top: calc(2.375rem + 13.5vw);
}
@media (min-width: 1200px) {
  .pt-200 {
    padding-top: 12.5rem;
  }
}
.pt-100 {
  padding-top: calc(1.75rem + 6vw);
}
@media (min-width: 1200px) {
  .pt-100 {
    padding-top: 6.25rem;
  }
}
.pt-75 {
  padding-top: calc(1.59375rem + 4.125vw);
}
@media (min-width: 1200px) {
  .pt-75 {
    padding-top: 4.6875rem;
  }
}
/*bottom */
.pb-300 {
  padding-bottom: calc(3rem + 21vw);
}
@media (min-width: 1200px) {
  .pb-300 {
    padding-bottom: 18.75rem;
  }
}
.pb-200 {
  padding-bottom: calc(2.375rem + 13.5vw);
}
@media (min-width: 1200px) {
  .pb-200 {
    padding-bottom: 12.5rem;
  }
}
.pb-100 {
  padding-bottom: calc(1.75rem + 6vw);
}
@media (min-width: 1200px) {
  .pb-100 {
    padding-bottom: 6.25rem;
  }
}
.pb-150 {
  padding-bottom: calc(2.0625rem + 9.75vw);
}
@media (min-width: 1200px) {
  .pb-150 {
    padding-bottom: 9.375rem;
  }
}
.pb-75 {
  padding-bottom: calc(1.59375rem + 4.125vw);
}
@media (min-width: 1200px) {
  .pb-75 {
    padding-bottom: 4.6875rem;
  }
}
.pb-50 {
  padding-bottom: calc(1.4375rem + 2.25vw);
}
@media (min-width: 1200px) {
  .pb-50 {
    padding-bottom: 3.125rem;
  }
}
/* Left */
.ps-200,
.pl-200 {
  padding-left: calc(2.375rem + 13.5vw);
}
@media (min-width: 1200px) {
  .ps-200,
  .pl-200 {
    padding-left: 12.5rem;
  }
}
.ps-100,
.pl-100 {
  padding-left: calc(1.75rem + 6vw);
}
@media (min-width: 1200px) {
  .ps-100,
  .pl-100 {
    padding-left: 6.25rem;
  }
}
/*Right */
.pe-200,
.pr-200 {
  padding-right: calc(2.375rem + 13.5vw);
}
@media (min-width: 1200px) {
  .pe-200,
  .pr-200 {
    padding-right: 12.5rem;
  }
}
.pe-100,
.pr-100 {
  padding-right: calc(1.75rem + 6vw);
}
@media (min-width: 1200px) {
  .pe-100,
  .pr-100 {
    padding-right: 6.25rem;
  }
}

/*---------Font Weights --------------*/
/* Switched to using bootstrap font-weights */
.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

/****------ End Font weight -----*/

/* ---------- Line heights ----------*/
.lh-p145 {
  line-height: 145%;
}
.lh-p120 {
  line-height: 120%;
}

.lh-p100 {
  line-height: 100%;
}
.lh-28 {
  line-height: 28px;
}
.lh-56 {
  line-height: 56.9px;
}
.lh-52 {
  line-height: 52.2px;
}
.lh-32 {
  line-height: 32px;
}
.lh-24 {
  line-height: 24px;
}

.pointer {
  cursor: pointer;
}
