/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap'); */

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
} */

:root {
  --primary-color: #475367;
  --secondary-color: #34ed72;
  --green-2: #27b256;
}

body {
  font-family: 'Open Sans', sans-serif;
  /* color: var(--primary-color); */
  scroll-behavior: smooth;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: var(--primary-color);
}
/*
.material-symbols-outlined {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
} */
