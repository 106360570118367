/* Base CSS */

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-LightItalic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Book.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-BookItalic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-MediumItalic.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-BoldItalic.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Black.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-BlackItalic.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

:root {
  --vital-primary: #008751;
  --vital-secondary: #d3ffee;
  --dark-900: #13322b;
  --grey-500: #667185;
  --grey-400: #98a2b3;
  --grey-300: #d0d5dd;
  --grey-200: #e4e7ec;
  --grey-100: #f0f2f5;
}

a {
  transition: 0.3s ease;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  outline: 0 solid;
  text-decoration: none;
}

a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  font-weight: 700;
}

body {
  font-family: 'Circular Std', 'Open Sans', sans-serif;
  /* background: #f8f8fa; */
  background-color: #e0e0e047;
  overflow-x: hidden;
}

.primary-color {
  color: #337ab7;
}

.section-padding {
  padding: 80px 0;
}

.section-title {
  margin-top: 50px;
}

.section-title h2 {
  color: #000;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Circular Std', sans-serif;
}

.section-title p {
  padding: 0 72px;
}

/* Navbar */

.navbar-default {
  background-color: #fff;
  transition: all 0.5s;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e7e7e7;
  padding: 0px;
}

/* .nav > li > a {
  padding: 0px;
} */

.navbar {
  margin-bottom: 0;
}

.navbar-right,
.navbar-alu {
  padding-top: 25px;
}

.navbar-brand {
  padding: 0px;
  height: inherit;
  font-family: 'Circular Std', sans-serif;
}

.navbar-default .navbar-brand {
  color: #fff;
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  color: #27b000;
  background-color: transparent;
  text-transform: uppercase;
}

.navbar-default .navbar-nav > li > a {
  text-transform: uppercase;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #3dae38;
}

.navbar-fixed-top {
  padding: 0px;
  transition: all 0.5s;
}

.top-nav-collapse {
  background: rgba(0, 0, 0, 0.8);
  padding: 25px 0;
}

/* specific nabvar */
.navbar-alu {
  padding-top: 32px;
}

.navbar-default .navbar-alu > li > a {
  font-size: 19px;
  color: #686a6c;
  padding: 0px 20px;
  text-transform: uppercase;
}

li.logout {
  display: inline-block;
}

.navbar-right li.logout a {
  border: 1px solid #5ab32d;
  color: #5ab32d;
  border-radius: 15px;
  padding: 10px 35px;
  display: block;
  font-size: 19px;
  text-align: center;
  transition: 0.3s;
}

.navbar-right li.logout a:hover {
  color: #fff;
  background: #5ab32d;
}

/*tab style Menu */
a.single-tab {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 4px 18px #ccc;
  margin: 0px 0px 50px;
  padding: 10px 5px;
  display: block;
  min-height: 0px; /*280px*/
  border-radius: 12px;
  transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
}

div.single-tab {
  background-color: #fff;
  box-shadow: 0px 4px 18px #ccc;
  /* vertical-align: middle; */
  padding-top: 10px;
  text-align: center;
  display: block;
  min-height: 50px;
  font-size: 1.5em;
  transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
}

div.single-tab.active-tab {
  background-color: #5ab32d;
  color: #fff;
}

.inner-tab {
  text-align: center;
  padding: 43px 5px 2px;
}

a.single-tab span {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #5ab32d;
  color: #fff;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 46px;
}

a.single-tab i {
  font-size: 140px;
  text-align: center;
  color: #000;
}

a.single-tab img {
  width: 60px;
}

a.single-tab p {
  padding: 9px 0px 0px;
  text-transform: uppercase;
  font-size: 19px;
  color: #666;
}

a.single-tab.active-tab {
  background-color: #5ab32d;
  color: #fff;
}

a.single-tab.active-tab p {
  color: #fff;
  text-transform: capitalize;
}

a.single-tab.active-tab > span {
  background-color: #fff;
  color: #5ab32d;
}

a.single-tab:active,
a.single-tab:hover {
  background-color: #5ab32d;
}

a.single-tab:hover > span {
  background-color: #fff;
  color: #5ab32d;
}

a.single-tab:hover > i.fa {
  color: #fff;
}

.expire {
  color: #e8304b;
  font-size: 22px;
  padding: 24px 14px;
}

.expire.text-right p {
  margin: 0;
}

/* Patient form */
.patient-heading {
  text-align: center;
  padding: 25px;
}

.patient-heading h2 {
  color: #449bd2;
  text-transform: uppercase;
}

.patient-heading p {
  color: #86888a;
  font-size: 20px;
}

span.rdots {
  width: 12px;
  height: 12px;
  background: red;
  padding: 1px;
  display: inline-block;
  border-radius: 50px;
  margin: 0px 5px;
}

p.form-element input {
  width: 100%;
}

/* label {
  color: #429ad1;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 10px;
  position: relative;
} */

label.hiddden {
  visibility: hidden;
}

label.l_dots:after {
  width: 12px;
  height: 12px;
  background: red;
  padding: 1px;
  display: block;
  position: absolute;
  right: -21px;
  top: 3px;
  content: ' ';
  border-radius: 50px;
}

.patient-form-area {
  padding: 50px 0;
  background: #fff;
  display: block;
  overflow: hidden;
  margin-bottom: 150px;
  box-shadow: 0px 0px 16px #ddd;
  -webkit-box-shadow: 0px 0px 16px #ddd;
  -moz-box-shadow: 0px 0px 16px #ddd;
  -o-box-shadow: 0px 0px 16px #ddd;
}

.patient-form-area form.p-form input.form-control,
select.form-control {
  height: 41px;
}

input.btn.btn-primary.btn-update {
  float: right;
  padding: 6px 25px;
  margin: 30px 0px 40px;
  background: #429ad1;
}

/*=======================
 Layouts
 ======================*/
header {
  background-color: #fff;
  transition: all 0.5s;
  margin-bottom: 0;
  padding: 10px 0px;
}

.sticky-footer {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  background-color: #fff;
}

.btn-spinner {
  margin-top: 5px;
  float: right;
  margin-left: 10px;
}

/*=======================
 Entry Page
 ======================*/
section.menu-tab {
  padding: 30px 0px 10px;
}

a.single-tab .innertab2 img {
  width: 59px;
  text-align: center;
  margin: 0 auto;
}

.credit i {
  font-size: 22px;
  color: #fff;
  background: #ef3a41;
  padding: 11px;
  margin-right: 7px;
  width: 43px;
  text-align: center;
}

.credit {
  color: #ef3a41;
  background: #fff;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid red;
  border-radius: 5px;
  width: 100%;
  display: table;
  margin-bottom: 10px;
}

.credit strong {
  width: 78%;
  text-align: center;
  font-size: 16px;
  display: table-cell;
  vertical-align: middle;
}

.credit strong small {
  font-size: 12px;
  display: block;
}

.credit.mar-top {
  margin-top: 81px;
}

a.single-menu-tab.active-tab:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 23px solid transparent;
  border-top-color: #5ab32d;
  border-bottom: 0;
  margin-left: -23px;
  margin-bottom: -22px;
}

a.add_btn {
  text-align: center;
  background: #31abe8;
  display: block;
  color: #fff;
  width: 97px;
  height: 114px;
  border-radius: 10px;
  font-weight: 800;
  font-size: 13px;
}

a.add_btn span {
  font-size: 70px;
  display: block;
  font-weight: 300;
  line-height: 85px;
}

/*search__bar*/
.search-form {
  margin-top: 30px;
}

.search-form-element {
  border: 3px solid #59b32d;
  padding: 3px;
  border-radius: 50px;
  background: #fff;
  display: flex;
  padding: 10px;
}

.form-control:focus,
.form-select:focus,
.custom-select:focus {
  border-color: #59b32d;
  outline: 0;
  box-shadow: unset;
}

.search-icon {
  color: #59b32d;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  padding: 2px 5px;
}

i.cancel {
  width: 20px;
  height: 20px;
  background: #bcbec0;
  color: #fff;
  text-align: center;
  line-height: 20px;
  border-radius: 50px;
  font-size: 14px;
  margin: 7px;
}

/* input[type='search'] {
  border: none;
  font-size: 17px;
  font-weight: 600;
  width: 90%;
} */

section.add_patient_section {
  background: #fff;
}

section.search-sec {
  background: #fff;
  padding: 10px 0px 40px;
}

.single_form_patient p {
  text-align: right;
  color: #3d3d3d;
  font-weight: 600;
  padding-top: 7px;
}

.single_form_patient div {
  padding: 0px 5px;
  overflow: hiddden;
}

.single_form_patient input[type='text'] {
  width: 100%; /*81%*/
  background: #f7f7f7;
  border: 1px solid #eee;
  padding: 6px;
  margin-bottom: 7px;
  border-radius: 4px;
}

.single_form_patient select {
  width: 81%;
  background: #f7f7f7;
  border: 1px solid #eee;
  padding: 6px;
  margin-bottom: 7px;
  border-radius: 4px;
}

.first_patient_form {
  border-right: 2px solid #eaeaeb;
}

label.radio {
  color: #000;
  text-transform: none;
}

a#more_inforamtion {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

.single_form_patient input[type='submit'] {
  width: 81%;
  padding: 8px 0px;
  margin-top: 25px;
  background: #7edf4f;
  border: 0;
  color: #fff;
  font-size: 22px;
  border: 2px solid #59b32d;
  border-radius: 6px;
}

.single_form_patient strong {
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
}

.patient_forms {
  overflow: hidden;
  padding-bottom: 50px;
}

.radio_button_area {
  text-align: center;
  margin: 0 auto;
}

#more_forms {
  display: none;
}

#more_forms ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: italic;
}

#more_forms ::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
}

#more_forms :-ms-input-placeholder {
  /* IE 10+ */
  font-style: italic;
}

#more_forms :-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
}

/* Nice Scroll */
.nicescroll-cursors {
  background-color: rgb(42, 176, 1) !important;
  border: 1px solid #000666 !important;
}

.nav-item {
  padding: 0px 15px;
  text-align: left;
  font-size: 1.1rem;
}

.nav-tabs .nav-item {
  padding: 10px !important  ;
  color: #337ab7;
  margin: auto;
  box-shadow: 0px 4px 18px #ccc;
}

.align-items-middle {
  display: flex;
  align-items: center;
}

.align-grid-items-middle {
  display: grid;
  align-items: center;
}

.align-items-bottom {
  display: flex;
  align-items: flex-end;
}

.align-items-bottom-right {
  display: grid;
  align-items: flex-end;
  text-align: right;
}

.auto-height {
  height: auto;
}

/* .nav-link.active {
  padding: 10px !important  ;
  background: #5ab32d !important;
  color: #fff !important;
} */

/* =================================
 ====== Responsive Media Query =====
 ==================================*/

/* Medium Layout: 1280px. */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-default .navbar-nav > li > a {
    padding: 0px 15px;
    font-size: 16px;
  }

  li.logout a {
    padding: 6px 25px !important;
    display: inline-block;
  }

  .single_form_patient p {
    text-align: left;
  }

  .single_form_patient div {
    padding: 0px 5px;
    overflow: hiddden;
    clear: both;
  }
}
/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .navbar-brand > img {
    width: 167px;
  }

  .navbar-toggle {
    margin-top: 15px;
  }

  .navbar-nav {
    margin: 0;
  }

  .navbar-default .navbar-nav > li > a {
    padding: 10px 20px;
  }

  li.logout {
    margin-bottom: 25px;
  }

  a.single-tab {
    min-height: 0px;
  }
  /*min-height: 230px*/
  a.single-tab img {
    width: 65px;
  }

  label {
    margin-top: 8px;
  }

  label.hiddden {
    visibility: hidden;
    display: none;
  }

  .single_form_patient p {
    text-align: left;
  }

  /* Entry page */
  /* 
  .nav-item {
    padding: 0px 25px;
  } */

  .credit.mar-top {
    margin-top: 0;
  }

  .credit {
    width: 60%;
    margin: 0px auto 5px;
  }

  .single_form_patient input[type='submit'] {
    width: 100%;
    font-size: 100%;
  }
}
/* Wide Mobile Layout: 480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/* Additional style */
.custom-single-icon {
  font-size: 20px;
  cursor: pointer;
}

.custom-group-icon {
  font-size: 20px;
  margin-left: 9px;
  cursor: pointer;
}
.btn-icon {
  padding: unset;
}

.search-result {
  padding: 5px;
  background: white;
  list-style: none;
  margin-left: 10px;
}

.search-result:hover {
  color: #212529;
  /* background-color: rgba(0, 0, 0, 0.075); */
  cursor: pointer;
  cursor: pointer;
  background-color: #ddeef7;
}
.search-result-panel {
  position: absolute;
  min-width: 555px;
  height: auto;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
  border: 1px solid #0094ff;
  box-shadow: 0 0 10px #333333;
  background-color: white;
}

.align-items-middle {
  display: flex;
  align-items: center;
}

.align-grid-items-middle {
  display: grid;
  align-items: center;
}

.align-items-bottom {
  display: flex;
  align-items: flex-end;
}

.align-items-bottom-right {
  display: grid;
  align-items: flex-end;
  text-align: right;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  /* margin-top: 20px; */
  background-color: transparent;
  margin-bottom: 20px;
  border-right: 1px solid #eee;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #1489c8;
  /* color: white; */
  border-right: 1px solid #eee;
}

.icon-inset-left {
  position: absolute;
  margin-top: -35px;
  left: 20px;
  cursor: pointer;
  padding: 2px 15px;
  font-size: 1.2rem;
}
.icon-inset-right {
  position: absolute;
  margin-top: -35px;
  right: 20px;
  cursor: pointer;
  padding: 2px 15px;
  font-size: 1.2rem;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link-custom {
  font-weight: 300;
  color: #fff;
}

.sidebar .nav-link-custom.active {
  color: #ffffff;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.nav-link-custom.active {
  background-color: #0c80be;
  color: white;
}

.nav-link-custom:hover {
  background-color: #0c80be;
  color: white;
}

.nav-link-custom {
  color: white;
}

.nav-item-custom {
  padding: 7px;
  text-align: center;
}

.navbar .navbar-toggler {
  /* top: 1rem; */
  left: 1rem;
  border-color: transparent;
}

.navbar-custom {
  height: 60px;
  background-color: #ffffff;
}

label.required:after {
  content: ' *';
  color: red;
}

/* progress bar animation : https://codepen.io/michaelbarys/pen/rVvVJx*/
.progress-bar-custom {
  width: 0;
  -webkit-animation: progress 5s ease-in-out forwards;
  animation: progress 5s ease-in-out forwards;
  background-color: #5ba532;
}
.progress-bar-custom .title {
  opacity: 0;
  -webkit-animation: show 0.35s forwards ease-in-out 0.5s;
  animation: show 0.35s forwards ease-in-out 0.5s;
}

@-webkit-keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*progress bar animation end */

/* media screen and  767px*/
@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }

  .sidebar-sticky {
    margin-top: -80px !important;

    width: 40%;
  }

  .request-spinner {
    right: 5%;
  }
}

@media (max-width: 575.98px) {
  .modal-window > div {
    width: 360px;
  }

  .sidebar-sticky {
    /* margin-top: 40px !important; */
    width: 55%;
  }
}

.detail-label {
  padding: 7px 0px;
  background-color: white;
  font-weight: bolder;
}

.prescription-card {
  cursor: pointer;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
}

.prescription-card:hover {
  background-color: rgba(242, 238, 238, 0.2);
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.prescription-card-text {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card {
  margin: 10px 0px;
  border-color: transparent;
  border-radius: 10px;
}

.patient-message-card {
  padding: 5px;
}

.btn-rounded {
  border-radius: 20px;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
}

.content-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
}

.refresh-toast {
  float: right;
  padding: 50px;
  position: fixed;
  left: 50%;
  color: #191489;
  font-size: 1.3rem;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  top: 50%;
  background-color: white;
  font-weight: 600;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.card-shadow:hover {
  box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.card-shadow {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.card-shadow-success:hover {
  box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: #28a745 !important;
}

.logo-loader {
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: #fdfdfd;
  opacity: 0.9;
  display: flex;
  align-items: center;
}

.logo-loader-img {
  animation: pulse 3s linear infinite;
  width: 128px;
  height: 128px;
  position: relative;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1.1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.1);
  }
}

.patient-tab,
.patient-tab .nav-item .nav-link.active {
  border: unset !important;
  background-color: transparent !important;
}

.patient-tab .nav-item {
  padding: 5px;
}

.label-section {
  border: 1px solid #337ab7;
  width: 100%;
}

.label-section .subtext {
  font-size: 12px;
  line-height: 15px;
  font-weight: 350;
  letter-spacing: 0.2px;
}

/***  Vitals Project **/

.vitals-bg-primary {
  background-color: var(--vital-primary);
}

.vitals-bg-secondary {
  background-color: var(--vital-secondary);
}

/* Textx */

.vitals-text-primary {
  color: var(--vital-primary);
}

.vitals-text-secondary {
  color: var(--vital-secondary);
}

.vitals-text-dark {
  color: var(--dark-900);
}

.vitals-img-70 {
  width: 70px;
}

/* Border */

.vitals-border-primary {
  border: 1px solid var(--vital-primary);
}

.vital-form-control {
  padding: 10px 20px;
  border-color: var(--grey-300);
  border-width: 1px;
}
.vital-form-control:focus {
  border-color: var(--grey-300);
  border-width: 1.1px;
}

/* Has poor support in FF: https://caniuse.com/?search=has() */
label:has(+ input:required):after {
  content: ' *';
  color: red;
}
